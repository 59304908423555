import React from 'react';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-react-components';

interface Props extends PropsWithClassProps {
  fill?: string;
}

export function IconShare({ classProps, fill = 'none' }: Props) {
  return (
    <svg
      className={classProps?.root}
      width="20"
      height="17"
      viewBox="0 0 20 17"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5657 8.22765L12.0629 1.72437V5.61322C4.45714 6.85767 1.80796 12.8725 1.43408 15.7244C6.41188 11.1614 10.594 10.7696 12.0629 11.1441V14.9898L18.5657 8.22765Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
}
