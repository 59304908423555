import React from 'react';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-react-components';

export function IconShareAlternative({ classProps }: PropsWithClassProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classProps?.root}
    >
      <path
        d="M8.94258 3.54199H7.29223C5.11518 3.54199 3.75 5.08348 3.75 7.26496V13.1523C3.75 15.3338 5.10852 16.8753 7.29223 16.8753H13.5397C15.7241 16.8753 17.0833 15.3338 17.0833 13.1523V11.8627"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0838 7.1294V3.54199M17.0838 3.54199H13.4964M17.0838 3.54199L11.4912 9.13458"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
